body, html {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center; /* 세로 방향으로 중앙 정렬 */
  height: 100vh; /* 뷰포트 높이에 맞춤 */
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0; /* 밝은 회색 배경 */
}

.content {
  text-align: center;
}

h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 24px;
}

p {
  color: #666;
  font-size: 18px;
  margin-bottom: 32px;
}

button {
  border-radius: 25px;
  padding: 12px 30px;
  font-size: 18px;
  color: white;
  background-color: #ff4081;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

button:hover {
  transform: scale(1.05);
}

/* 반응형 디자인 조정 */
@media (max-width: 768px) {
  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
  }
}


/* 마크다운 카드 스타일 */
.markdown-card {
  text-align: left;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px; /* 내부 여백은 유지 */
  margin: 20px 0; /* 상하 여백 유지 */
}

/* PC 환경에서의 스타일 */
@media (min-width: 768px) {
  .content {
    padding-left: 20%; /* 좌측 여백 증가 */
    padding-right: 20%; /* 우측 여백 증가 */
  }
}

/* 모바일 환경에서의 스타일 */
@media (max-width: 767px) {
  .content {
    padding-left: 10px; /* 좌측 여백 감소 */
    padding-right: 10px; /* 우측 여백 감소 */
  }
}
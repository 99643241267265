body {
	background-color: #f0f0f0; /* 밝은 회색 배경 */
}

.home-container {
  padding: 80px;
}

.ip-address-title, .ant-card-title, .ant-typography {
  color: #eb2f96 !important; /* Pink color for text */
}

.info-card {
  background-color: #fff0f6; /* Light pink background */
  border-radius: 20px; /* Rounded corners */
  border: 1px solid #ffadd2; /* Pink border */
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  color: #eb2f96; /* Pink loading text */
}

/* Adjust the Navbar as needed */
.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensures Navbar stays above other content */
  /* Add other styles as needed */
}

/* If needed, add a specific class for content below Navbar */
.content-below-navbar {
  margin-top: 20px; /* This adds space between the Navbar and the Spin/content below */
}

.info-card .card-text {
  font-size: 16px; /* 글자 크기 조정 */
}

.ip-address-title {
  font-size: 24px; /* IP 주소 타이틀의 글자 크기 조정 */
}
